import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row',
    width: '100%',
    columnGap: '10px',
  },
  linkContainer: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: '10px',
    alignItems: 'center',
  },
  linkLbl: {
    color: theme.palette.green,
  },
  disabledLink: {
    color: '#7B7B7B',
    pointerEvents: 'none',
  },
}))

export default useStyles
