import React, { useState, useEffect } from 'react'
import { Typography, Grid, TextField, Tooltip, Button } from '@material-ui/core'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import FormHelperText from '@material-ui/core/FormHelperText'
import ImageIcon from '@material-ui/icons/ImageOutlined'
import InputAdornment from '@material-ui/core/InputAdornment'
import formError from '../../utils/customErrorMessages'

function FormContent(props) {
  const { generateId, formState, handleChange, hasError, status, classes, validator } = props
  const url_prefix = process.env.REACT_APP_API_STORAGE_URL
  const hiddenFileInput = React.useRef(null)
  const [isDisabled, setIsDisabled] = useState(0)

  const handleClick = () => {
    hiddenFileInput.current.click()
  }

  useEffect(() => {
    setIsDisabled(status === 'view' ? true : false)
    return () => {
      setIsDisabled(0)
    }
  }, [])

  const imageDisplay = () => {
    // There's an image set
    if (formState.values?.image_url?.length > 0) {
      switch (typeof formState.values.image_url) {
        case 'string':
          var image_url = `${url_prefix}${formState.values.image_url}`
          return <img src={image_url} alt="" className={classes.imageIcon} />
        case 'object':
          if (!hasError('image_url') && formState.values.image_url[0].type.includes('image')) {
            return (
              <img
                src={URL.createObjectURL(formState.values.image_url[0])}
                alt=""
                className={classes.imageIcon}
              />
            )
          }
          validator.errors.add('image_url', 'The image url must be an image.')
      }
    }
    return <ImageIcon color="primary" className={classes.emptyImageIcon} />
  }

  return (
    <>
      <Grid item xs={4} className={classes.formGroupContainer}>
        <div className={classes.uploadGroupContainer}>
          <input
            ref={hiddenFileInput}
            accept="image/*"
            className={classes.fileInput}
            id="image_url"
            type="file"
            name="image_url"
            onChange={handleChange}
            hidden
            disabled={isDisabled}
          />
          <Tooltip title={isDisabled ? '' : '画像を選択'}>
            <label color="primary" htmlFor="image_url">
              {imageDisplay()}
            </label>
          </Tooltip>
          {hasError('image_url') && (
            <FormHelperText error={true} className={classes.textCenter}>
              {formError('画像', formState.errors.first('image_url'))}
            </FormHelperText>
          )}
        </div>
        <div className={classes.uploadGroupContainer}>
          {status !== 'view' ? (
            <Button className={classes.submitBtn} onClick={handleClick}>
              素材アップロード
            </Button>
          ) : null}
        </div>
      </Grid>

      <Grid item xs={8} className={classes.formGroupContainer}>
        <div className={classes.formGroup}>
          <Typography variant="h6" className={classes.textInputLabel}>
            素材ID :
          </Typography>
          <TextField
            variant="outlined"
            className={clsx(classes.addMaterialTextField, classes.viewTextField)}
            disabled
            type="text"
            value={generateId || ''}
            name="id"
          />
        </div>
        <div className={classes.formGroup}>
          <Typography variant="h6" className={classes.textInputLabel}>
            タイトル :
          </Typography>
          <TextField
            className={clsx(
              classes.addMaterialTextField,
              status === 'view' ? classes.viewTextField : null
            )}
            error={hasError('name')}
            helperText={hasError('name') ? formError('タイトル') : null}
            name="name"
            label=""
            type="text"
            onChange={handleChange}
            value={formState.values.name || ''}
            variant="outlined"
            disabled={status === 'view' ? true : false}
          />
        </div>
        <div className={classes.formGroup}>
          <Typography variant="h6" className={classes.textInputLabel}>
            価格 :
          </Typography>
          <TextField
            variant="outlined"
            error={hasError('price')}
            helperText={hasError('price') ? formError('価格') : null}
            name="price"
            label=""
            type="number"
            className={clsx(
              classes.addMaterialTextField,
              status === 'view' ? classes.viewTextField : null
            )}
            onChange={handleChange}
            value={formState.values.price || ''}
            disabled={status === 'view' ? true : false}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" className={classes.priceIcon}>
                  ¥
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className={classes.formGroup}></div>
      </Grid>
    </>
  )
}

FormContent.propTypes = {
  handleChange: PropTypes.func.isRequired,
  formState: PropTypes.object.isRequired,
  generateId: PropTypes.number,
  hasError: PropTypes.func.isRequired,
  status: PropTypes.string,
  classes: PropTypes.object,
  validator: PropTypes.object,
}

export default FormContent
