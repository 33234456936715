import * as types from './actionTypes'

const initialState = {
  list: [],
  activeTemplate: 'Template',
  listMeta: {
    totalCount: 0,
    currentPage: 1,
    lastPage: 1,
    pageSize: 10,
    prevPageUrl: null,
    nextPageUrl: null,
  },
  search: {
    keyword: '',
    limit: 10,
    page: 1,
    sort: 'desc',
    sortBy: 'updated_at',
  },
  //template
  themeCategories: [],
  templateList: [],
  detailTemplateByid: {},
  //announcement
  details: [],
  detailsById: {},
}

function reducer(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case types.GET_ANNOUNCEMENT_LIST:
      return { ...state, details: payload }
    case types.GET_ANNOUNCEMENT_DETAIL_BY_ID:
      return { ...state, detailsById: payload }
    case types.GET_TEMPLATE_FILTERS:
      return { ...state, themeCategories: payload }
    // The following case clauses are wrapped into blocks using brackets to avoid eslint no-case-declarations
    case types.GET_TEMPLATE_LIST: {
      const currentPage = formulateCurrentPage(
        payload.meta.currentPage,
        payload.meta.total,
        payload.meta.perPage
      )

      return {
        ...state,
        templateList: payload.data,
        listMeta: {
          totalCount: payload.meta.total,
          currentPage: currentPage,
          lastPage: payload.meta.lastPage,
          pagSize: payload.meta.perPage,
          prevPageUrl: payload.meta.previousPageUrl,
          nextPageUrl: payload.meta.nextPageUrl,
        },
        search: {
          ...state.search,
          limit: payload.meta.perPage,
          page: currentPage,
        },
      }
    }
    case types.SET_ACTIVE_TEMPLATES:
      return {
        ...state,
        activeTemplate: payload,
      }
    case types.SET_TEMPLATE_SEARCH_CRITERIA:
      return {
        ...state,
        search: {
          ...state.search,
          ...payload,
        },
      }
    case types.GET_TEMPLATE_DETAILS_BY_ID:
      return { ...state, detailTemplateByid: payload }
    default:
      return state
  }
}

function formulateCurrentPage(currentPage, totalCount, pageSize) {
  let totalPages = Math.ceil(totalCount / pageSize)
  let newCurrentPage = currentPage

  if (totalPages === 0) {
    // There are no entries, set current page to 1.
    newCurrentPage = 1
  } else if (totalPages < currentPage) {
    // The current page is out of bounds, return last page instead
    newCurrentPage = totalPages
  }

  return newCurrentPage
}
export default reducer
