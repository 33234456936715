import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    width: '100%',
    rowGap: '20px',
  },
  titleWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexGrow: 1,
  },
  title: {
    color: theme.palette.green,
    fontWeight: 'bold',
  },
  button: {
    backgroundColor: theme.palette.green,
    color: 'white',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: theme.palette.green,
      boxShadow: 'none',
    },
  },
  filterContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  sortContainer: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: '20px',
  },
  sortButton: {
    backgroundColor: 'unset',
    border: '1px solid #15B5B3',
    color: theme.palette.green,
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  searchBar: {
    width: 'auto',
    border: '1px solid #15B5B3',
    display: 'inline-block',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    '& button': {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    '& input': {
      paddingLeft: theme.spacing(1),
      color: theme.palette.green,
    },
  },
  iconButton: {
    color: theme.palette.green,
  },
  viewOptions: {
    border: '1px solid #15B5B3',
    borderRadius: 4,
    '& button': {
      padding: 7,
      height: 40,
    },
  },
  viewOptionsIcon: {
    color: theme.palette.green,
  },
  rightContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    columnGap: 10,
  },
}))

export default useStyles
