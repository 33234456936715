import React, { useEffect } from 'react'
import { Router as ReactRouter, Switch, Route } from 'react-router-dom'
import { matchPath } from 'react-router'
import { createBrowserHistory } from 'history'
import { useSelector } from 'react-redux'
import { useTemplateContext } from 'utils/use-isEditTemplate'

import routes from './routes'
import Public from './Public'
import UserPrivate from './Private'
import NotFound from '../views/NotFound'

const history = createBrowserHistory()

function Router() {
  const auth = useSelector((state) => state.auth)
  const { templateHook } = useTemplateContext()
  const unlisten = history.listen((location) => {
    if (auth.user) {
      checkAuthUser(auth.user.data.role, location.pathname)
    }
  })

  /**
   * check path if equal
   * @param {string} role
   * @param {string} pathname
   *
   * @return null or true
   */
  const matchPathname = (path, expectedPath) => {
    return matchPath(path, {
      path: expectedPath,
      exact: true,
      strict: false,
    })
  }

  /**
   * check paths if creating template or edit template
   * @param {string} role
   * @param {string} pathname
   *
   * @return void
   */
  const checkAuthUser = (role, pathname) => {
    if (role === 'Administrator') {
      const matchCreateTemplate = matchPathname(pathname, '/admin/templates/create')
      const matchEditTemplate = matchPathname(pathname, '/admin/templates/edit/:id')
      if (matchCreateTemplate || matchEditTemplate) {
        templateHook({ type: 'update', payload: true })
      } else {
        templateHook({ type: 'update', payload: false })
      }
    }

    if (role === 'CompanyAdmin') {
      const matchCreateTemplate = matchPathname(pathname, '/company-admin/templates/create')
      const matchEditTemplate = matchPathname(pathname, '/company-admin/templates/edit/:id')
      if (matchCreateTemplate || matchEditTemplate) {
        templateHook({ type: 'update', payload: true })
      } else {
        templateHook({ type: 'update', payload: false })
      }
    }

    if (role === 'User') {
      const matchCreateTemplate = matchPathname(pathname, '/user/catalogs/create/:id')
      const matchEditTemplate = matchPathname(pathname, '/user/catalogs/edit/:id')
      if (matchCreateTemplate || matchEditTemplate) {
        templateHook({ type: 'update', payload: true })
      } else {
        templateHook({ type: 'update', payload: false })
      }
    }
  }

  useEffect(() => {
    if (auth.user) {
      const pathname = history.location?.pathname
      checkAuthUser(auth.user.data.role, pathname)
    }
    return () => unlisten()
  }, [auth.user])

  return (
    <>
      <ReactRouter history={history}>
        <Switch>
          {routes.map((route, i) => {
            return route.auth ? (
              <UserPrivate exact key={i} {...route} />
            ) : (
              <Public exact key={i} {...route} />
            )
          })}
          <Route component={NotFound} />
        </Switch>
      </ReactRouter>
    </>
  )
}

export default Router
