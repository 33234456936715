import React, { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Typography, Button, IconButton, Paper, InputBase } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import SwapVertIcon from '@material-ui/icons/SwapVert'
import SearchIcon from '@material-ui/icons/Search'
import PropTypes from 'prop-types'
import useStyles from './style'
import AppsIcon from '@material-ui/icons/Apps'
import ViewListIcon from '@material-ui/icons/ViewList'
import ShoppingCart from '@material-ui/icons/ShoppingCart'

function HeaderContentAction(props) {
  const classes = useStyles()
  const [firstSort, setFirstSort] = useState(false)
  const [secondSort, setSecondSort] = useState(false)

  const handleFirstSort = () => {
    // update state
    setFirstSort((prev) => !prev)
    if (!firstSort) {
      props.onClickFirstSort('asc')
    } else {
      props.onClickFirstSort('desc')
    }
  }

  const handleSecondSort = () => {
    // update state
    setSecondSort((prev) => !prev)
    if (!secondSort) {
      props.onClickSecondSort('asc')
    } else {
      props.onClickSecondSort('desc')
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.titleWrapper}>
        <Typography variant="h5" className={classes.title}>
          {props.title}
        </Typography>
        <div className={classes.rightContainer}>
          {props.redirectToCart && (
            <RouterLink to={props.redirectToCart}>
              <Button className={classes.button} startIcon={<ShoppingCart />}>
                買い物かご
              </Button>
            </RouterLink>
          )}
          <RouterLink to={props.redirectTo}>
            <Button variant="contained" className={classes.button} startIcon={<AddIcon />}>
              {props.addLbl}
            </Button>
          </RouterLink>
        </div>
      </div>
      <div className={classes.filterContainer}>
        <div className={classes.sortContainer}>
          {props.showViewOption ? (
            <div className={classes.viewOptions}>
              <Button onClick={() => props.handleViewListChange(false)}>
                <AppsIcon className={classes.viewOptionsIcon} />
              </Button>
              <Button onClick={() => props.handleViewListChange(true)}>
                <ViewListIcon className={classes.viewOptionsIcon} />
              </Button>
            </div>
          ) : null}
          <Button
            className={classes.sortButton}
            endIcon={<SwapVertIcon />}
            onClick={handleFirstSort}
          >
            {props.firstSortLbl}
          </Button>
          <Button
            className={classes.sortButton}
            endIcon={<SwapVertIcon />}
            onClick={handleSecondSort}
          >
            {props.secondSortLbl}
          </Button>
        </div>
        <Paper component="form" className={classes.searchBar} onSubmit={props.onSearch}>
          <InputBase
            className={classes.input}
            placeholder="検索"
            name="keyword"
            value={props.valueSearch}
            onChange={props.onSearchChange}
          />
          <IconButton type="submit" className={classes.iconButton} aria-label="search">
            <SearchIcon />
          </IconButton>
        </Paper>
      </div>
    </div>
  )
}

HeaderContentAction.propTypes = {
  title: PropTypes.string,
  firstSortLbl: PropTypes.string,
  secondSortLbl: PropTypes.string,
  addLbl: PropTypes.string,
  redirectTo: PropTypes.string.isRequired,
  onSearch: PropTypes.func.isRequired,
  valueSearch: PropTypes.string.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  onClickFirstSort: PropTypes.func.isRequired,
  onClickSecondSort: PropTypes.func.isRequired,
  showViewOption: PropTypes.bool,
  handleViewListChange: PropTypes.func,
  redirectToCart: PropTypes.string,
}

export default HeaderContentAction
