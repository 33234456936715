import React, { useRef, useState } from 'react'
import {
  Button,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  btnSelectCompany: {
    backgroundColor: theme.palette.green,
    color: 'white',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: theme.palette.green,
      boxShadow: 'none',
    },
    '& .MuiButton-iconSizeMedium > *:first-child': {
      fontSize: '25px',
    },
  },
}))

function Select(props) {
  const { list } = props
  const classes = useStyles()
  const companyDropdownRef = useRef(null)
  const [openCompanyDropdown, setOpenCompanyDropdown] = useState(false)
  const [selectedData, setSelectedData] = useState({ id: null, label: '会社を選択してください' })

  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpenCompanyDropdown(false)
    }
  }

  const handleClose = (event) => {
    if (companyDropdownRef.current && companyDropdownRef.current.contains(event.target)) {
      return
    }

    setOpenCompanyDropdown(false)
  }

  const onChangeValue = (value) => {
    setSelectedData(value)
    setOpenCompanyDropdown(false)
  }

  return (
    <>
      <Button
        ref={companyDropdownRef}
        onClick={() => setOpenCompanyDropdown(true)}
        className={classes.btnSelectCompany}
        endIcon={<ArrowDropDownIcon />}
      >
        {selectedData.label}
      </Button>
      <Popper
        open={openCompanyDropdown}
        anchorEl={companyDropdownRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: 1 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper className={classes.test}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={openCompanyDropdown}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem onClick={() => onChangeValue({ id: null, label: 'All Companies' })}>
                    All Companies
                  </MenuItem>
                  {Object.entries(list).map(([value, label], index) => (
                    <MenuItem onClick={() => onChangeValue({ id: value, label })} key={index}>
                      {label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}

Select.propTypes = {
  list: PropTypes.object.isRequired,
}

export default Select
