import React from 'react'
import PropTypes from 'prop-types'
import FormHelperText from '@material-ui/core/FormHelperText'

function HelperTextError({ children, ...other }) {
  return (
    <FormHelperText error={true} style={{ marginLeft: '14px' }} {...other}>
      {children}
    </FormHelperText>
  )
}

HelperTextError.propTypes = {
  children: PropTypes.node.isRequired,
}

export default HelperTextError
