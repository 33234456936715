import React, { useState } from 'react'
import { Button, IconButton, Paper, InputBase } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import ShoppingCart from '@material-ui/icons/ShoppingCart'
import PropTypes from 'prop-types'
import useStyles from './style'
import { Link as RouterLink } from 'react-router-dom'

function UserTemplateHeaderContent(props) {
  const classes = useStyles()
  const [isaActiveTemplate, setisaActiveTemplate] = useState(props.isActiveTemplate)
  const [isActiveCatalogue, setisActiveCatalogue] = useState(props.isActiveCatalogue)
  const [isActiveMyDesign, setisActiveMyDesign] = useState(props.isActiveMyDesign)

  const handleTemplateList = () => {
    props.onChangeTab('Template')
    setisaActiveTemplate(true)
    setisActiveCatalogue(false)
    setisActiveMyDesign(false)
    props.onClickTemplateSort(props.valueSearch)
  }

  const handleCatalogueList = () => {
    props.onChangeTab('Catalog')
    setisaActiveTemplate(false)
    setisActiveMyDesign(false)
    setisActiveCatalogue(true)
    props.onClickCatalogueSort(props.valueSearch)
  }

  const handleMyDesignList = () => {
    props.onChangeTab('MyDesign')
    setisaActiveTemplate(false)
    setisActiveCatalogue(false)
    setisActiveMyDesign(true)
    props.onClickMyDesignSort(props.valueSearch)
  }

  return (
    <div className={classes.root}>
      <div className={classes.filterContainer}>
        <div className={classes.sortContainer}>
          <Button
            onClick={handleTemplateList}
            className={isaActiveTemplate ? classes.sortButtonActive : classes.sortButton}
          >
            {props.firstSortLbl}
          </Button>
          <Button
            onClick={handleCatalogueList}
            className={isActiveCatalogue ? classes.sortButtonActive : classes.sortButton}
          >
            {props.secondSortLbl}
          </Button>
          <Button
            onClick={handleMyDesignList}
            className={isActiveMyDesign ? classes.sortButtonActive : classes.sortButton}
          >
            {props.thirdSortLbl}
          </Button>
        </div>
        <div className={classes.rightContainer}>
          <Button
            component={RouterLink}
            to="/user/cart"
            className={classes.cartButton}
            startIcon={<ShoppingCart />}
          >
            カートを見る
          </Button>

          <Paper component="form" className={classes.searchBar} onSubmit={props.onSearch}>
            <InputBase
              className={classes.input}
              placeholder="検索"
              name="keyword"
              value={props.valueSearch}
              onChange={props.onSearchChange}
            />
            <IconButton type="submit" className={classes.iconButton} aria-label="search">
              <SearchIcon />
            </IconButton>
          </Paper>
        </div>
      </div>
    </div>
  )
}

UserTemplateHeaderContent.propTypes = {
  firstSortLbl: PropTypes.string,
  secondSortLbl: PropTypes.string,
  thirdSortLbl: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
  valueSearch: PropTypes.string.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  onClickTemplateSort: PropTypes.func.isRequired,
  onClickCatalogueSort: PropTypes.func.isRequired,
  onClickMyDesignSort: PropTypes.func.isRequired,
  onChangeTab: PropTypes.func,
  isActiveTemplate: PropTypes.bool,
  isActiveCatalogue: PropTypes.bool,
  isActiveMyDesign: PropTypes.bool,
}

export default UserTemplateHeaderContent
