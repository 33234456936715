import * as types from './actionTypes'

const initialState = {
  topThemes: {},
}

function reducer(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case types.GET_TOP_THEMES:
      return {
        ...state,
        topThemes: payload.data,
      }
    default:
      return state
  }
}
export default reducer
