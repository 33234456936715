import React from 'react'
import PropTypes from 'prop-types'

function FlexCol({ children, ...rest }) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }} {...rest}>
      {children}
    </div>
  )
}

FlexCol.propTypes = {
  children: PropTypes.node.isRequired,
}

export default FlexCol
