const _ = require('lodash')

const formError = (field, error) => {
  let string = ''
  let errorMessage = ''

  if (field === Japanese.email) {
    string = Japanese.errEmailCorrectForm
    if (error.includes('required')) {
      string = `${field}${Japanese.errFieldItem} `
    }
    if (error.includes('taken')) {
      string = `${Japanese.errEmailExists} `
    }
    if (error.includes('user')) {
      string = `${Japanese.errEmailNotRegistered} `
    }
  } else {
    string = `${field}${Japanese.errFieldItem} `

    if (field === Japanese.enterCurrentPassword && error.includes('Invalid Password')) {
      errorMessage = Japanese.errCurrentPassword
    }
    if (field === Japanese.enterNewPassword && error.includes('must contain')) {
      errorMessage = Japanese.errNewPassword
    }
    if (field === Japanese.enterNewPassword && error.includes('does not match')) {
      errorMessage = Japanese.errConfirmPassword
    }
    if (field === Japanese.enterConfirmPassword && error.includes('does not match')) {
      errorMessage = Japanese.errConfirmPassword
    }
    if (_.has(error?.validation, field)) {
      errorMessage = error?.validation[field][0]
    }
    if (field === Japanese.phone_number && error.includes('invalid')) {
      errorMessage = Japanese.errPhoneNumber
    }
    if (field === Japanese.image && error.includes('greater')) {
      errorMessage = Japanese.errImageSize
    }
    if (field === Japanese.image && error.includes('must be an image')) {
      errorMessage = Japanese.errImageType
    }
    if (field === Japanese.publish_date && error.includes('yesterday')) {
      errorMessage = Japanese.errPublishDate
    }
  }

  if (errorMessage) {
    string = errorMessage
  }

  return string
}

export default formError

const Japanese = {
  phone_number: '電話番号',
  email: 'メールアドレス',
  image: '画像',
  publish_date: '公開日',
  enterCurrentPassword: '現在のパスワード',
  enterNewPassword: '新しいパスワード',
  enterConfirmPassword: '再度新しいパスワード',
  errEmailExists: 'このメールアドレスは既に登録済みです',
  errEmailNotRegistered: '入力されたメールアドレスは無効もしくは登録されていません',
  errEmailCorrectForm: '正しいフォームでメールアドレスを入力してください',
  errFieldItem: 'を入力してください',
  errCurrentPassword: '現在の正しいパスワードを入力してください',
  errNewPassword:
    'パスワードには次の文字を含める必要があります：大文字1文字、特殊文字1文字、最低8文字',
  errConfirmPassword: '新しいパスワードと確認用のパスワードが一致しません',
  errPasswordNotMatch: 'パスワードの確認が一致しません',
  errMatchPassword: '新しいパスワードは現在と異なるパスワードを入力してください',
  errPhoneNumber: '電話フィールドには数字のみを含めることができます。',
  errImageSize: 'アップロードされたファイルサイズが大きすぎます。2MBを超えないようにしてください。',
  errImageType: '画像は画像である必要があります。',
  errPublishDate: '公開日は昨日以降でなければなりません',
}
