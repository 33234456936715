import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { LinearProgress } from '@material-ui/core'

const GreenLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#15B5B3',
  },
}))((props) => <LinearProgress {...props} />)

export default GreenLinearProgress
