import React, { lazy, Suspense } from 'react'
import { useSelector } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'

import Main from 'layouts/main'
import { Loader } from 'components'
import NowAllowed from '../views/NowAllowed'

function UserPrivate(props) {
  const { component, layout, ...rest } = props
  const auth = useSelector((state) => state.auth)
  if (auth.user) {
    localStorage.setItem('role', auth.user.data.role)
  }

  if (!auth.isAuthenticated) {
    return (
      <Route
        {...rest}
        render={(props) => (
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location },
            }}
          />
        )}
      />
    )
  }

  const Layout = layout ? lazy(() => import(`../layouts/${layout}`)) : Main
  const Component = lazy(() => import(`../${component}`))
  const pathRole = rest.role
  const role = localStorage.getItem('role')
  const renderLoader = Loader

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          {pathRole == role ? (
            <Suspense fallback={renderLoader()}>
              <Layout>
                {' '}
                <Component {...props} />{' '}
              </Layout>
            </Suspense>
          ) : (
            <NowAllowed />
          )}
        </>
      )}
    />
  )
}

UserPrivate.propTypes = {
  component: PropTypes.any,
  layout: PropTypes.any,
  location: PropTypes.object,
}

export default UserPrivate
