import MuiButton from './MuiButton'
import MuiIconButton from './MuiIconButton'
import MuiPaper from './MuiPaper'
import MuiTableCell from './MuiTableCell'
import MuiTableHead from './MuiTableHead'
import MuiTypography from './MuiTypography'
import MuiTableRow from './MuiTableRow'
import MuiAppBar from './MuiAppBar'
import MuiFilledInput from './MuiFilledInput'
import MuiOutlinedInput from './MuiOutlinedInput'
import MuiInputBase from './MuiInputBase'
import MuiTab from './MuiTab'

export default {
  MuiButton,
  MuiIconButton,
  MuiPaper,
  MuiTableCell,
  MuiTableHead,
  MuiTypography,
  MuiTableRow,
  MuiAppBar,
  MuiFilledInput,
  MuiOutlinedInput,
  MuiInputBase,
  MuiTab,
}
