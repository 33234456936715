import { useReducer } from 'react'

const reducer = (state, action) => {
  switch (action.type) {
    case 'update':
      return {
        isEdittingTemplate: action.payload,
      }
    default:
      return state
  }
}

export const useHookReducer = () => useReducer(reducer, { isEdittingTemplate: false })
