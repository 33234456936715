import * as types from './actionTypes'

const initialState = {
  link: {
    data: null,
  },
  linkDetails: {
    data: null,
  },
}

const reducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case types.SET_UPDATE_LINK:
      return {
        ...state,
        link: {
          data: payload,
        },
      }
    case types.SET_LINK_DETAILS:
      return {
        ...state,
        linkDetails: {
          data: payload,
        },
      }
    default:
      return state
  }
}

export default reducer
