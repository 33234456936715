import React, { useState, useEffect } from 'react'
import { ThemeProvider } from '@material-ui/styles'
import IdleTimer from 'utils/IdleTimer'
import { useDispatch, useSelector } from 'react-redux'
import { TemplateContextProvider, useHookReducer } from 'utils/use-isEditTemplate'
import { signOutUser } from 'services/auth'
import WebFont from 'webfontloader'
import fonts from 'utils/fontNames'
import 'typeface-roboto'

import Router from './router/Router'
import theme from './theme'
import 'assets/scss/index.scss'

function App() {
  const dispatch = useDispatch()
  const auth = useSelector((state) => state.auth)
  const [state, hookDispatch] = useHookReducer()
  const [isTimeout, setIsTimeout] = useState(false)

  useEffect(() => {
    WebFont.load({
      google: {
        families: fonts,
      },
      timeout: 3000,
    })
  }, [])

  useEffect(() => {
    const timer = new IdleTimer({
      timeout: 600,
      onTimeout: () => {
        setIsTimeout(true)
      },
      onExpired: () => {
        setIsTimeout(true)
      },
    })
    // start timer only when authenticated
    if (auth.isAuthenticated && !state.isEdittingTemplate) {
      timer.init()
    }

    if (state.isEdittingTemplate) {
      timer.cleanUp()
    }

    return () => {
      timer.cleanUp()
      setIsTimeout(false)
    }
  }, [auth.isAuthenticated, state.isEdittingTemplate])

  useEffect(() => {
    if (isTimeout && auth.isAuthenticated) {
      dispatch(signOutUser())
      setIsTimeout(false)
    }
  }, [isTimeout])

  return (
    <ThemeProvider theme={theme}>
      <TemplateContextProvider value={{ templateHook: hookDispatch }}>
        <Router />
      </TemplateContextProvider>
    </ThemeProvider>
  )
}

export default App
