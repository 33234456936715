import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Pagination from '@material-ui/lab/Pagination'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      padding: theme.spacing(1),
    },
  },
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& ul.MuiPagination-ul li button.MuiPaginationItem-page.Mui-selected': {
      backgroundColor: '#41CCC0',
      color: 'white',
    },
  },
}))

const Paginator = ({ pageCount, onPageChange, currentPage }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Pagination
        count={pageCount}
        onChange={onPageChange}
        page={currentPage}
        className={classes.pagination}
        shape="rounded"
      />
    </div>
  )
}

Paginator.propTypes = {
  pageCount: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
}

export default Paginator
