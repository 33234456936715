import Role from 'utils/Role'

export default [
  {
    path: '/',
    component: 'views/sign-in',
    layout: 'minimal',
  },
  {
    path: '/admin/dashboard',
    component: 'views/admin/dashboard',
    auth: true,
    role: Role.ADMIN,
  },
  {
    path: '/admin/users',
    component: 'views/admin/users',
    auth: true,
    name: 'ユーザー',
    role: Role.ADMIN,
  },
  {
    path: '/admin/users/create',
    component: 'views/admin/users/AddUser',
    name: 'ユーザー追加',
    auth: true,
    role: Role.ADMIN,
  },
  {
    path: '/admin/users/edit/:id',
    component: 'views/admin/users/EditUser',
    auth: true,
    name: 'ユーザー編集',
    role: Role.ADMIN,
  },
  {
    path: '/admin/users/view/:id',
    component: 'views/admin/users/ViewUser',
    auth: true,
    name: 'ユーザー表示',
    role: Role.ADMIN,
  },
  {
    path: '/admin/themes',
    component: 'views/admin/themes',
    auth: true,
    name: 'テーマ',
    role: Role.ADMIN,
  },
  {
    path: '/admin/themes/view/:id',
    component: 'views/admin/themes/ViewTheme',
    auth: true,
    name: 'テーマ表示',
    role: Role.ADMIN,
  },
  {
    path: '/admin/themes/create',
    component: 'views/admin/themes/AddTheme',
    auth: true,
    name: 'テーマ追加',
    role: Role.ADMIN,
  },
  {
    path: '/admin/themes/edit/:id',
    component: 'views/admin/themes/EditTheme',
    auth: true,
    name: 'テーマ編集',
    role: Role.ADMIN,
  },
  {
    path: '/admin/themes/edit-category/:id',
    component: 'views/admin/themes/components/EditThemeCategories',
    auth: true,
    name: 'テーマカテゴリの編集',
    role: Role.ADMIN,
  },
  {
    path: '/admin/templates',
    component: 'views/admin/templates',
    auth: true,
    name: 'テンプレート',
    role: Role.ADMIN,
  },
  {
    path: '/admin/templates/view/:id',
    component: 'views/admin/templates/ViewTemplate',
    auth: true,
    name: 'テンプレート表示',
    role: Role.ADMIN,
  },
  {
    path: '/admin/templates/create',
    component: 'views/admin/templates/AddTemplate',
    auth: true,
    role: Role.ADMIN,
    name: 'テンプレート作成',
  },
  {
    path: '/admin/templates/edit/:id',
    component: 'views/admin/templates/EditTemplate',
    auth: true,
    role: Role.ADMIN,
    name: 'テンプレート作成',
  },
  {
    path: '/admin/templates/print/:id',
    component: 'views/admin/templates/PrintTemplate',
    auth: true,
    name: 'テンプレート印刷',
    role: Role.ADMIN,
  },
  {
    path: '/admin/notifications',
    component: 'views/admin/notifications',
    auth: true,
    role: Role.ADMIN,
  },
  {
    path: '/admin/logs',
    component: 'views/admin/logs',
    auth: true,
    role: Role.ADMIN,
  },
  {
    path: '/admin/announcement',
    component: 'views/admin/announcement',
    auth: true,
    role: Role.ADMIN,
    name: 'アナウンスリスト',
  },
  {
    path: '/admin/announcement/create',
    component: 'views/admin/announcement/AddAnnouncement',
    auth: true,
    role: Role.ADMIN,
    name: 'アナウンスを作成する',
  },
  {
    path: '/admin/announcement/view/:id',
    component: 'views/admin/announcement/ViewAnnouncement',
    auth: true,
    name: 'アナウンスを見る',
    role: Role.ADMIN,
  },
  {
    path: '/admin/announcement/edit/:id',
    component: 'views/admin/announcement/EditAnnouncement',
    auth: true,
    name: 'アナウンスを編集する',
    role: Role.ADMIN,
  },
  {
    path: '/sign-in',
    component: 'views/sign-in',
    layout: 'minimal',
  },
  {
    path: '/sign-up',
    component: 'views/sign-up',
    layout: 'minimal',
  },
  {
    path: '/forgot-password',
    component: 'views/forgot-password',
    layout: 'minimal',
  },
  {
    path: '/password/reset',
    component: 'views/reset-password',
    layout: 'minimal',
  },
  {
    path: '/activate',
    component: 'views/activate',
    layout: 'minimal',
  },
  {
    path: '/admin/companies',
    component: 'views/admin/company',
    auth: true,
    name: '企業',
    role: Role.ADMIN,
  },
  {
    path: '/admin/companies/view/:id',
    component: 'views/admin/company/ViewCompany',
    auth: true,
    name: '会社を見る',
    role: Role.ADMIN,
  },
  {
    path: '/admin/companies/create',
    component: 'views/admin/company/AddCompany',
    auth: true,
    name: '企業を作る',
    role: Role.ADMIN,
  },
  {
    path: '/admin/companies/edit/:id',
    component: 'views/admin/company/EditCompany',
    auth: true,
    name: '企業の編集',
    role: Role.ADMIN,
  },
  {
    path: '/user/dashboard',
    component: 'views/user/dashboard',
    auth: true,
    name: 'ダッシュボード',
    role: Role.USER,
  },
  {
    path: '/user/logs',
    component: 'views/user/logs',
    auth: true,
    role: Role.USER,
  },
  {
    path: '/company-admin/dashboard',
    component: 'views/companyAdmin/dashboard',
    auth: true,
    role: Role.COMPANY_ADMIN,
  },
  {
    path: '/company-admin/logs',
    component: 'views/companyAdmin/logs',
    auth: true,
    role: Role.COMPANY_ADMIN,
  },
  {
    path: '/admin/profile',
    component: 'views/profile',
    auth: true,
    role: Role.ADMIN,
  },
  {
    path: '/company-admin/profile',
    component: 'views/profile',
    auth: true,
    role: Role.COMPANY_ADMIN,
  },
  {
    path: '/user/profile',
    component: 'views/profile',
    auth: true,
    role: Role.USER,
  },
  //material
  {
    path: '/admin/materials',
    component: 'views/admin/materials',
    auth: true,
    role: Role.ADMIN,
    name: '素材一覧',
  },
  {
    path: '/admin/materials/view/:id',
    component: 'views/admin/materials/ViewMaterial',
    auth: true,
    role: Role.ADMIN,
    name: '素材表示',
  },
  {
    path: '/admin/materials/create',
    component: 'views/admin/materials/AddMaterial',
    auth: true,
    role: Role.ADMIN,
    name: '素材追加',
  },
  {
    path: '/admin/materials/edit/:id',
    component: 'views/admin/materials/EditMaterial',
    auth: true,
    role: Role.ADMIN,
    name: '素材編集',
  },
  //companyAdmin/users
  {
    path: '/company-admin/users',
    component: 'views/companyAdmin/users',
    auth: true,
    role: Role.COMPANY_ADMIN,
    name: 'ユーザー一覧',
  },
  {
    path: '/company-admin/users/view/:id',
    component: 'views/companyAdmin/users/ViewUser',
    auth: true,
    role: Role.COMPANY_ADMIN,
    name: 'ユーザー表示',
  },
  {
    path: '/company-admin/users/edit/:id',
    component: 'views/companyAdmin/users/EditUser',
    auth: true,
    role: Role.COMPANY_ADMIN,
    name: 'ユーザー編集',
  },
  {
    path: '/company-admin/users/create',
    component: 'views/companyAdmin/users/AddUser',
    auth: true,
    role: Role.COMPANY_ADMIN,
    name: 'ユーザー追加',
  },
  //companyAdmin/themes
  {
    path: '/company-admin/themes',
    component: 'views/companyAdmin/themes',
    auth: true,
    name: 'テーマ',
    role: Role.COMPANY_ADMIN,
  },
  {
    path: '/company-admin/themes/view/:id',
    component: 'views/companyAdmin/themes/ViewTheme',
    auth: true,
    name: 'テーマ表示',
    role: Role.COMPANY_ADMIN,
  },
  {
    path: '/company-admin/themes/create',
    component: 'views/companyAdmin/themes/AddTheme',
    auth: true,
    name: 'テーマ追加',
    role: Role.COMPANY_ADMIN,
  },
  {
    path: '/company-admin/themes/edit/:id',
    component: 'views/companyAdmin/themes/EditTheme',
    auth: true,
    name: 'テーマ編集',
    role: Role.COMPANY_ADMIN,
  },
  {
    path: '/company-admin/themes/edit-category/:id',
    component: 'views/companyAdmin/themes/components/EditThemeCategories',
    auth: true,
    name: 'テーマカテゴリの編集',
    role: Role.COMPANY_ADMIN,
  },
  //companyAdmin/templates
  {
    path: '/company-admin/templates',
    component: 'views/companyAdmin/templates',
    auth: true,
    name: 'テンプレート',
    role: Role.COMPANY_ADMIN,
  },
  {
    path: '/company-admin/templates/view/:id',
    component: 'views/companyAdmin/templates/ViewTemplate',
    auth: true,
    name: 'テンプレート表示',
    role: Role.COMPANY_ADMIN,
  },
  {
    path: '/company-admin/templates/create',
    component: 'views/companyAdmin/templates/AddTemplate',
    auth: true,
    role: Role.COMPANY_ADMIN,
    name: 'テンプレート作成',
  },
  {
    path: '/company-admin/templates/edit/:id',
    component: 'views/companyAdmin/templates/EditTemplate',
    auth: true,
    role: Role.COMPANY_ADMIN,
    name: 'テンプレート作成',
  },
  {
    path: '/company-admin/templates/print/:id',
    component: 'views/companyAdmin/templates/PrintTemplate',
    auth: true,
    name: 'テンプレート印刷',
    role: Role.COMPANY_ADMIN,
  },
  //company-admmin/material
  {
    path: '/company-admin/materials',
    component: 'views/companyAdmin/materials',
    auth: true,
    role: Role.COMPANY_ADMIN,
    name: '素材一覧',
  },
  {
    path: '/company-admin/materials/view/:id',
    component: 'views/companyAdmin/materials/ViewMaterial',
    auth: true,
    role: Role.COMPANY_ADMIN,
    name: '素材表示',
  },
  {
    path: '/company-admin/materials/create',
    component: 'views/companyAdmin/materials/AddMaterial',
    auth: true,
    role: Role.COMPANY_ADMIN,
    name: '素材追加',
  },
  {
    path: '/company-admin/materials/edit/:id',
    component: 'views/companyAdmin/materials/EditMaterial',
    auth: true,
    role: Role.COMPANY_ADMIN,
    name: '素材編集',
  },
  {
    path: '/company-admin/logs',
    component: 'views/companyAdmin/logs',
    auth: true,
    role: Role.COMPANY_ADMIN,
  },
  // Company Admin Announcement
  {
    path: '/company-admin/announcement',
    component: 'views/companyAdmin/announcement',
    auth: true,
    role: Role.COMPANY_ADMIN,
    name: 'アナウンスリスト',
  },
  {
    path: '/company-admin/announcement/create',
    component: 'views/companyAdmin/announcement/AddAnnouncement',
    auth: true,
    role: Role.COMPANY_ADMIN,
    name: 'アナウンスを作成する',
  },
  {
    path: '/company-admin/announcement/view/:id',
    component: 'views/companyAdmin/announcement/ViewAnnouncement',
    auth: true,
    name: 'アナウンスを見る',
    role: Role.COMPANY_ADMIN,
  },
  {
    path: '/company-admin/announcement/edit/:id',
    component: 'views/companyAdmin/announcement/EditAnnouncement',
    auth: true,
    name: 'アナウンスを編集する',
    role: Role.COMPANY_ADMIN,
  },
  {
    path: '/user/catalogs',
    component: 'views/user/templates',
    auth: true,
    role: Role.USER,
    name: 'デザイン一覧',
  },
  {
    path: '/user/catalogs/view/:id',
    component: 'views/user/catalogs/ViewCatalog',
    auth: true,
    role: Role.USER,
    name: 'デザインを見る',
  },
  {
    path: '/user/catalogs/create/:id',
    component: 'views/user/catalogs/AddCatalog',
    auth: true,
    role: Role.USER,
    name: 'デザイン作成',
  },
  {
    path: '/user/catalogs/edit/:id',
    component: 'views/user/catalogs/EditCatalog',
    auth: true,
    role: Role.USER,
    name: 'デザイン編集',
  },
  {
    path: '/user/catalogs/print/:id',
    component: 'views/user/catalogs/PrintCatalog',
    auth: true,
    name: 'デザイン印刷',
    role: Role.USER,
  },
  {
    path: '/user/catalogs/print-template/:id',
    component: 'views/user/catalogs/PrintTemplate',
    auth: true,
    name: 'テンプレート印刷',
    role: Role.USER,
  },
  {
    path: '/user/cart',
    component: 'views/user/cart',
    auth: true,
    name: 'カートを見る',
    role: Role.USER,
  },
  {
    path: '/company-admin/cart',
    component: 'views/user/cart',
    auth: true,
    name: '会社買い物かご',
    role: Role.COMPANY_ADMIN,
  },
]
