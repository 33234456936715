import * as types from './actionTypes'

const initialState = {
  list: [],
  listMeta: {
    totalCount: 0,
    currentPage: 1,
    lastPage: 1,
    pageSize: 10,
    prevPageUrl: null,
    nextPageUrl: null,
  },
  search: {
    keyword: '',
    limit: 10,
    page: 1,
    sort: 'desc',
    sortBy: 'updated_at',
  },
  details: {
    id: 0,
    first_name: '',
    last_name: '',
    email: '',
    name: '',
    tel_number: '',
    affiliated_company: '',
    affiliated_sales_office: '',
    avatar: null,
    status: {
      id: 0,
      name: '',
    },
    password: '',
    role: '',
    company_id: '',
  },
  generateId: 0,
}

function reducer(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case types.SET_MODAL_VALUES:
      return {
        ...state,
        details: {
          ...state.details,
          id: payload.id ?? state.details.id,
          first_name: payload.first_name ?? state.details.first_name,
          last_name: payload.last_name ?? state.details.last_name,
          name: payload.full_name ?? state.details.name,
          affiliated_company: payload.affiliated_company ?? state.details.affiliated_company,
          affiliated_sales_office:
            payload.affiliated_sales_office ?? state.details.affiliated_sales_office,
          email: payload.email ?? state.details.email,
          avatar: payload.avatar ?? state.details.avatar,
          status: payload.status ?? state.details.status,
          password: payload.password ?? state.details.password,
          role: payload.role.name ?? state.details.role.name,
          tel_number: payload.tel_number ?? state.details.tel_number,
          company_id: payload.company_id ?? '',
          company: payload.company ?? {},
        },
      }
    case types.CREATE_USER:
    case types.UPDATE_USER:
    case types.DELETE_USER:
      return state
    case types.CLEAR_MODAL_VALUES:
      return {
        ...state,
        details: {
          ...initialState.details,
        },
      }
    // The following case clauses are wrapped into blocks using brackets to avoid eslint no-case-declarations
    case types.SEARCH_USER: {
      const currentPage = formulateCurrentPage(
        payload.meta.currentPage,
        payload.meta.total,
        payload.meta.perPage
      )

      return {
        ...state,
        list: payload.data,
        listMeta: {
          totalCount: payload.meta.total,
          currentPage: currentPage,
          lastPage: payload.meta.lastPage,
          pagSize: payload.meta.perPage,
          prevPageUrl: payload.meta.previousPageUrl,
          nextPageUrl: payload.meta.nextPageUrl,
        },
        search: {
          ...state.search,
          limit: payload.meta.perPage,
          page: currentPage,
        },
      }
    }
    case types.SET_USER_SEARCH_CRITERIA:
      return {
        ...state,
        search: {
          ...state.search,
          ...payload,
        },
      }
    case types.GET_MAX_ID:
      return {
        ...state,
        generateId: payload?.generated_id,
      }
    default:
      return state
  }
}

function formulateCurrentPage(currentPage, totalCount, pageSize) {
  let totalPages = Math.ceil(totalCount / pageSize)
  let newCurrentPage = currentPage

  if (totalPages === 0) {
    // There are no entries, set current page to 1.
    newCurrentPage = 1
  } else if (totalPages < currentPage) {
    // The current page is out of bounds, return last page instead
    newCurrentPage = totalPages
  }

  return newCurrentPage
}

export default reducer
