import * as types from './actionTypes'

const initialState = {
  list: [],
  listMeta: {
    totalCount: 0,
    currentPage: 1,
    lastPage: 1,
    pageSize: 10,
    prevPageUrl: null,
    nextPageUrl: null,
  },
  search: {
    keyword: '',
    limit: 10,
    page: 1,
    sort: 'desc',
    sortBy: 'updated_at',
  },
  details: {},
}

function reducer(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case types.SET_MATERIAL_DETAILS:
      return { ...state, details: payload }
    case types.CREATE_MATERIAL:
    case types.UPDATE_MATERIAL:
    case types.DELETE_MATERIAL:
      return state
    case types.SEARCH_MATERIAL: {
      const currentPage = formulateCurrentPage(
        payload.meta.currentPage,
        payload.meta.total,
        payload.meta.perpage
      )
      return {
        ...state,
        list: payload.data,
        listMeta: {
          totalCount: payload.meta.total,
          currentPage: currentPage,
          lastPage: payload.meta.lastPage,
          pagSize: payload.meta.perPage,
          prevPageUrl: payload.meta.previousPageUrl,
          nextPageUrl: payload.meta.nextPageUrl,
        },
        search: {
          ...state.search,
          limit: payload.meta.perPage,
          page: currentPage,
        },
      }
    }
    case types.SET_MATERIAL_SEARCH_CRITERIA:
      return {
        ...state,
        search: {
          ...state.search,
          ...payload,
        },
      }
    default:
      return state
  }
}

function formulateCurrentPage(currentPage, totalCount, pageSize) {
  let totalPages = Math.ceil(totalCount / pageSize)
  let newCurrentPage = currentPage

  if (totalPages === 0) {
    // There are no entries, set current page to 1.
    newCurrentPage = 1
  } else if (totalPages < currentPage) {
    // The current page is out of bounds, return last page instead
    newCurrentPage = totalPages
  }

  return newCurrentPage
}

export default reducer
