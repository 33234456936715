const selectedFonts = [
  'Noto Sans JP',
  'Noto Serif JP',
  'M PLUS Rounded 1c',
  'M PLUS 1p',
  'Sawarabi Mincho',
  'Sawarabi Gothic',
  'Kosugi Maru',
  'Klee One',
  'Shippori Mincho',
  'Rampart One',
  'Kosugi',
  'Kaisei Tokumin',
  'Kaisei Decol',
  'Kaisei Opti',
  'Kaisei HarunoUmi',
  'RocknRoll One',
  'Hina Mincho',
  'Yomogi',
  'Kiwi Maru',
  'Palette Mosaic',
  'Dela Gothic One',
  'Reggae One',
  'Yusei Magic',
  'Shippori Mincho B1',
  'DotGothic16',
  'Potta One',
  'Hachi Maru Pop',
  'Stick',
  'Train One',
  'Otomanopee One',
  'New Tegomin',
]

export default selectedFonts
