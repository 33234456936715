import * as types from './actionTypes'

const initialState = {
  list: [],
  details: {},
}

function reducer(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case types.GET_CATEGORIES:
      return {
        ...state,
        list: payload,
      }
    case types.SET_CATEGORY_DETAIL:
      return {
        ...state,
        details: payload,
      }
    case types.UPDATE_CATEGORY:
      return state
    default:
      return state
  }
}

export default reducer
