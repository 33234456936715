import React from 'react'
import { Route } from 'react-router-dom'
import routes from 'router/routes'
import { Link, Typography } from '@material-ui/core'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import useStyles from './style'

function Breadcrumbs() {
  const classes = useStyles()

  return (
    <div>
      {routes.map(({ path }, key) => (
        <Route
          exact
          path={path}
          key={key}
          /* eslint-disable */ 
          render={(props) => {
            const crumbs = routes
              // Get all routes that contain the current one.
              // Remove root path
              .filter(({ path }) => props.match.path.includes(path) && path !== '/')
              // Swap out any dynamic routes with their param values.
              .map(({ path, ...rest }) => ({
                path: Object.keys(props.match.params).length
                  ? Object.keys(props.match.params).reduce(
                      (path, param) => path.replace(`:${param}`, props.match.params[param]),
                      path
                    )
                  : path,
                ...rest,
              }))
            
            return (
              <div className={classes.root}>
                {crumbs.map(({ name, path }, index) => {
                  return(
                    <div key={path} className={classes.linkContainer}>
                      <Typography variant="h5">
                        <Link href={path} className={crumbs.length - 1 !== index ? classes.linkLbl : classes.disabledLink}>{name}</Link>
                      </Typography>
                      {crumbs.length - 1 !== index && <NavigateNextIcon className={classes.linkLbl} />}
                    </div>
                  )
                })}
              </div>
            )
          }}
        />
      ))}
    </div>
  )
}

export default Breadcrumbs
