import React, { useEffect, useState } from 'react'
import { Typography, Grid, TextField, Select, MenuItem } from '@material-ui/core'
import PropTypes from 'prop-types'
import { HelperTextError } from 'primitives'
import clsx from 'clsx'
import formError from '../../utils/customErrorMessages'

function FormContent(props) {
  const { generateId, formState, handleChange, hasError, status, classes, categories } = props
  const [category, setCategory] = useState('')

  useEffect(() => {
    if (status === 'view') {
      const selected = categories.find((val) => val.id === formState.values.category_id)
      setCategory(selected?.context ?? '')
    }
    return () => setCategory('')
  }, [categories, formState.values.category_id])

  return (
    <>
      <Grid item xs={2} className={classes.formGroupContainer}></Grid>
      <Grid item xs={8} className={classes.formGroupContainer}>
        <div className={classes.formGroup}></div>
        <div className={classes.formGroup}>
          <Typography variant="h6" className={classes.textInputLabel}>
            テーマ ID :
          </Typography>
          <TextField
            variant="outlined"
            className={clsx(classes.textField, classes.viewTextField)}
            disabled
            type="text"
            value={generateId || ''}
            name="id"
          />
        </div>
        <div className={classes.formGroup}>
          <Typography variant="h6" className={classes.textInputLabel}>
            テーマ名 :
          </Typography>
          <TextField
            className={clsx(classes.textField, status === 'view' ? classes.viewTextField : null)}
            error={hasError('name')}
            helperText={hasError('name') ? formError('テーマ名') : null}
            name="name"
            label=""
            type="text"
            onChange={handleChange}
            value={formState.values.name || ''}
            variant="outlined"
            disabled={status === 'view' ? true : false}
          />
        </div>
        <div className={classes.formGroup}>
          <Typography variant="h6" className={classes.textInputLabel}>
            カテゴリ :
          </Typography>
          <div
            className={clsx(
              classes.textField,
              classes.justifyLeft,
              status === 'view' ? classes.viewTextField : null
            )}
          >
            {status === 'view' ? (
              <TextField
                variant="outlined"
                name="role"
                type="text"
                className={classes.viewTextField}
                value={category}
                disabled={true}
              />
            ) : (
              <>
                <Select
                  className={clsx(
                    classes.categoryField,
                    status === 'view' ? classes.viewTextField : null,
                    hasError('category_id') ? classes.categoryError : null
                  )}
                  value={categories.length != 0 ? formState.values.category_id : ''}
                  onChange={handleChange}
                  name="category_id"
                  label="Category"
                  disableUnderline
                >
                  {categories.map((category) => (
                    <MenuItem value={category.id} key={category.id}>
                      {category.context}
                    </MenuItem>
                  ))}
                </Select>
                {hasError('category_id') && (
                  <HelperTextError>{formError('種別名')}</HelperTextError>
                )}
              </>
            )}
          </div>
        </div>
        <div className={classes.formGroup}></div>
      </Grid>
    </>
  )
}

FormContent.propTypes = {
  handleChange: PropTypes.func.isRequired,
  formState: PropTypes.object.isRequired,
  generateId: PropTypes.number,
  hasError: PropTypes.func.isRequired,
  categories: PropTypes.array,
  status: PropTypes.string,
  classes: PropTypes.object,
  validator: PropTypes.object,
}

export default FormContent
