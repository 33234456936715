import * as types from './actionTypes'

const initialState = {
  isLoading: false,
  downloadRanking: {
    data: [],
    search: {
      page: 1,
      limit: 5,
    },
    listMeta: {
      totalCount: 0,
      currentPage: 1,
      lastPage: 1,
    },
  },
  editRanking: {
    data: [],
    search: {
      page: 1,
      limit: 5,
    },
    listMeta: {
      totalCount: 0,
      currentPage: 1,
      lastPage: 1,
    },
  },
}

function reducer(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case types.SET_IS_LOADING:
      return {
        ...state,
        isLoading: payload,
      }
    case types.GET_TEMPLATE_DOWNLOAD_RANKING: {
      const currentPage = formulateCurrentPage(
        payload.meta.currentPage,
        payload.meta.total,
        payload.meta.perPage
      )

      return {
        ...state,
        downloadRanking: {
          ...state.downloadRanking,
          data: payload.data,
          page: currentPage,
          listMeta: {
            totalCount: payload.meta.total,
            currentPage: currentPage,
            lastPage: payload.meta.lastPage,
            limit: payload.meta.perPage,
          },
        },
      }
    }
    case types.GET_TEMPLATE_EDITED_RANKING: {
      const currentPage = formulateCurrentPage(
        payload.meta.currentPage,
        payload.meta.total,
        payload.meta.perPage
      )

      return {
        ...state,
        editRanking: {
          ...state.editRanking,
          data: payload.data,
          page: currentPage,
          listMeta: {
            totalCount: payload.meta.total,
            currentPage: currentPage,
            lastPage: payload.meta.lastPage,
            limit: payload.meta.perPage,
          },
        },
      }
    }
    case types.DOWNLOAD_CHANGE_CRITERIA:
      return {
        ...state,
        downloadRanking: {
          ...state.downloadRanking,
          search: {
            ...state.search,
            ...payload,
          },
        },
      }
    case types.EDIT_CHANGE_CRITERIA:
      return {
        ...state,
        editRanking: {
          ...state.editRanking,
          search: {
            ...state.search,
            ...payload,
          },
        },
      }
    default:
      return state
  }
}

function formulateCurrentPage(currentPage, totalCount, pageSize) {
  let totalPages = Math.ceil(totalCount / pageSize)
  let newCurrentPage = currentPage

  if (totalPages === 0) {
    // There are no entries, set current page to 1.
    newCurrentPage = 1
  } else if (totalPages < currentPage) {
    // The current page is out of bounds, return last page instead
    newCurrentPage = totalPages
  }

  return newCurrentPage
}

export default reducer
