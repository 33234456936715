import React, { useEffect, useState } from 'react'
import { Typography, Grid, TextField } from '@material-ui/core'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import formError from '../../utils/customErrorMessages'
import postalCode from 'japan-postal-code'

function FormContent(props) {
  const { formState, handleChange, hasError, status, classes, children } = props
  const [pref, setPref] = useState(formState.values.prefecture)
  const [addr, setAddr] = useState(formState.values.address)

  useEffect(() => {
    postalCode.get(formState.values.postal_code, function (address) {
      setPref(address.prefecture)
      setAddr(address.city + address.area)
    })
    if (formState.values.postal_code == '') {
      setPref('')
      setAddr('')
    }
  }, [formState.values.postal_code])

  useEffect(() => {
    formState.values.prefecture = pref
    formState.errors.clear()
  }, [pref])

  useEffect(() => {
    formState.values.address = addr
    formState.errors.clear()
  }, [addr])

  const handleOnChange = (event) => {
    if (event.target.name == 'prefecture') {
      setPref(event.target.value)
    }
    if (event.target.name == 'address') {
      setAddr(event.target.value)
    }
    handleChange(event)
  }

  return (
    <>
      <Grid item xs={5} className={classes.formGroupContainer}>
        <div className={classes.uploadGroupContainer}>{children}</div>
        <div className={classes.formGroup}>
          <Typography variant="h6" className={classes.textInputLabel}>
            デザイン名 :
          </Typography>
          <TextField
            className={clsx(classes.addTextField, status === 'view' ? classes.viewTextField : null)}
            error={hasError('image_name')}
            helperText={hasError('image_name') ? formError('デザイン名') : null}
            name="image_name"
            label=""
            type="text"
            onChange={handleChange}
            value={formState.values.image_name || ''}
            variant="outlined"
            disabled={status === 'view' ? true : false}
          />
        </div>
        <div className={classes.formGroup}>
          <Typography variant="h6" className={classes.textInputLabel}>
            サイズ :
          </Typography>
          <TextField
            className={clsx(classes.addTextField, status === 'view' ? classes.viewTextField : null)}
            error={hasError('image_size')}
            helperText={hasError('image_size') ? formError('サイズ') : null}
            name="image_size"
            label=""
            type="image_size"
            onChange={handleChange}
            value={formState.values.image_size || ''}
            variant="outlined"
            disabled={true}
          />
        </div>
      </Grid>

      <Grid item xs={7} className={classes.formGroupContainer}>
        <div className={classes.formGroup}>
          <Typography variant="h6" className={classes.textInputLabel}>
            お名前 :
          </Typography>
          <TextField
            className={clsx(classes.addTextField, status === 'view' ? classes.viewTextField : null)}
            error={hasError('name')}
            helperText={hasError('name') ? formError('お名前') : null}
            name="name"
            label=""
            type="text"
            onChange={handleChange}
            value={formState.values.name || ''}
            variant="outlined"
            disabled={status === 'view' ? true : false}
          />
        </div>
        <div className={classes.formGroup}>
          <Typography variant="h6" className={classes.textInputLabel}>
            飲食店名 :
          </Typography>
          <TextField
            className={clsx(classes.addTextField, status === 'view' ? classes.viewTextField : null)}
            error={hasError('company')}
            helperText={hasError('company') ? formError('飲食店名') : null}
            name="company"
            label=""
            type="text"
            onChange={handleChange}
            value={formState.values.company || ''}
            variant="outlined"
            disabled={status === 'view' ? true : false}
          />
        </div>
        <div className={classes.formGroup}>
          <Typography variant="h6" className={classes.textInputLabel}>
            郵便番号 :
          </Typography>
          <TextField
            className={clsx(classes.addTextField, status === 'view' ? classes.viewTextField : null)}
            error={hasError('postal_code')}
            helperText={hasError('postal_code') ? formError('郵便番号') : null}
            name="postal_code"
            label=""
            type="text"
            onChange={handleChange}
            onKeyDown={(event) => {
              if (event.key == '.' || event.key === '-') {
                event.preventDefault()
              }
            }}
            value={formState.values.postal_code || ''}
            variant="outlined"
            disabled={status === 'view' ? true : false}
          />
        </div>
        <div className={classes.formGroup}>
          <Typography variant="h6" className={classes.textInputLabel}>
            都道府県 :
          </Typography>
          <TextField
            className={clsx(classes.addTextField, status === 'view' ? classes.viewTextField : null)}
            error={hasError('prefecture')}
            helperText={hasError('prefecture') ? formError('都道府県') : null}
            name="prefecture"
            id="prefecture"
            label=""
            type="text"
            onChange={handleOnChange}
            value={pref || ''}
            variant="outlined"
            disabled={status === 'view' ? true : false}
          />
        </div>
        <div className={classes.formGroup}>
          <Typography variant="h6" className={classes.textInputLabel}>
            住所 :
          </Typography>
          <TextField
            className={clsx(classes.addTextField, status === 'view' ? classes.viewTextField : null)}
            error={hasError('address')}
            helperText={hasError('address') ? formError('住所') : null}
            name="address"
            label=""
            type="text"
            onChange={handleOnChange}
            value={addr || ''}
            variant="outlined"
            disabled={status === 'view' ? true : false}
          />
        </div>
        <div className={classes.formGroup}>
          <Typography variant="h6" className={classes.textInputLabel}>
            電話番号 :
          </Typography>
          <TextField
            className={clsx(classes.addTextField, status === 'view' ? classes.viewTextField : null)}
            error={hasError('phone_number')}
            helperText={
              hasError('phone_number')
                ? formError('電話番号', formState.errors.first('phone_number'))
                : null
            }
            name="phone_number"
            label=""
            type="text"
            onChange={handleChange}
            value={formState.values.phone_number || ''}
            variant="outlined"
            disabled={status === 'view' ? true : false}
          />
        </div>
        <div className={classes.formGroup}>
          <Typography variant="h6" className={classes.textInputLabel}>
            メールアドレス :
          </Typography>
          <TextField
            className={clsx(classes.addTextField, status === 'view' ? classes.viewTextField : null)}
            error={hasError('email')}
            helperText={hasError('email') ? formError('メールアドレス') : null}
            name="email"
            label=""
            type="text"
            onChange={handleChange}
            value={formState.values.email || ''}
            variant="outlined"
            disabled={status === 'view' ? true : false}
          />
        </div>
      </Grid>
    </>
  )
}

FormContent.propTypes = {
  handleChange: PropTypes.func.isRequired,
  formState: PropTypes.object.isRequired,
  generateId: PropTypes.number,
  hasError: PropTypes.func.isRequired,
  status: PropTypes.string,
  classes: PropTypes.object,
  children: PropTypes.node,
}

export default FormContent
