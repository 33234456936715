import Http from 'utils/Http'
import { showLoader, hideLoader } from 'store/loader/actionCreators'
import {
  actionGetAnnouncementList,
  actionGetDetailsById,
  actionGetSessions,
  actionGetTemplateList,
  actionSetSearchCriteria,
  actionGetTemplateDetailById,
  actionSetActiveTemplates,
} from '../store/normalUser/actionCreators'

//announcement
export function getAnnouncementListDetails() {
  return (dispatch) => {
    dispatch(showLoader())

    return Http.get('users/get-announcement/')
      .then((response) => {
        dispatch(actionGetAnnouncementList(response.data))
      })
      .catch((error) => {
        console.log(error)
        // TODO Handle error throw a snackbar, alert, toast, or something
      })
      .finally(() => {
        dispatch(hideLoader())
      })
  }
}

export function getDetailsById(announcementId) {
  return (dispatch) => {
    dispatch(showLoader())

    return Http.get('users/' + announcementId)
      .then((response) => {
        dispatch(actionGetDetailsById(response.data))
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        dispatch(hideLoader())
      })
  }
}

//template
export function getTemplateSessionList() {
  return (dispatch) => {
    dispatch(showLoader())

    return Http.get('users/templates/get-sessionList/')
      .then((response) => {
        dispatch(actionGetSessions(response.data))
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        dispatch(hideLoader())
      })
  }
}

export function getTemplateList(keyword, page, limit, sort, sortBy, theme_ids) {
  return (dispatch) => {
    dispatch(showLoader())

    return Http.get('users/templates/', {
      params: {
        keyword,
        page,
        limit,
        sort,
        sortBy,
        theme_ids: theme_ids.join(','),
      },
    })
      .then((response) => {
        dispatch(actionGetTemplateList(response.data))
      })
      .catch((error) => {
        console.log(error)
        // TODO Handle error throw a snackbar, alert, toast, or something
      })
      .finally(() => {
        dispatch(hideLoader())
      })
  }
}

export function changeSearchCriteria(keyword, page, limit, sort, sortBy, theme_ids) {
  return (dispatch) => {
    dispatch(actionSetSearchCriteria(keyword, page, limit, sort, sortBy, theme_ids.join(',')))
  }
}

export function getTemplateDetailById(id) {
  return (dispatch) => {
    return Http.get('users/templates/' + id)
      .then((response) => {
        //  console.log(response.data)
        dispatch(actionGetTemplateDetailById(response.data))
        return response.data.data
      })
      .catch((error) => {
        console.log(error)
        // TODO Handle error throw a snackbar, alert, toast, or something
      })
  }
}

export function createLogs(templateId) {
  return () => {
    return Http.post('users/templates/logs', { template_id: templateId }).catch((error) => {
      console.log(error)
      // TODO Handle error throw a snackbar, alert, toast, or something
    })
  }
}

export function getActiveTemplates(data) {
  return (dispatch) => {
    dispatch(actionSetActiveTemplates(data))
  }
}

export function createPrintOrderItem(printOrderDetails) {
  return (dispatch) => {
    dispatch(showLoader())
    return Http.post('users/print-order-item', printOrderDetails)
      .then(({ data }) => {
        return data.data
      })
      .finally(() => {
        dispatch(hideLoader())
      })
  }
}
