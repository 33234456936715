class IdleTimer {
  constructor({ timeout, onTimeout = null, onExpired = null }) {
    this.timeout = timeout
    this.onTimeout = onTimeout
    this.onExpired = onExpired

    const expiredTime = parseInt(localStorage.getItem('_expiredTime'), 10)
    if (expiredTime > 0 && expiredTime < Date.now()) {
      this.onExpired()
      return
    }
    this.eventHandler = this.updateExpiredTime.bind(this)
  }

  init() {
    this.tracker()
    this.startInterval()
  }

  startInterval() {
    this.updateExpiredTime()
    this.interval = setInterval(() => {
      const expire = parseInt(localStorage.getItem('_expiredTime'), 10)
      if (expire < Date.now()) {
        if (this.onTimeout) {
          this.onTimeout()
          this.cleanUp()
        }
      }
    }, 1000)
  }

  updateExpiredTime() {
    localStorage.setItem('_expiredTime', Date.now() + this.timeout * 1000)
  }

  tracker() {
    window.addEventListener('mousemove', this.eventHandler)
    window.addEventListener('scroll', this.eventHandler)
    window.addEventListener('keydown', this.eventHandler)
  }

  cleanUp() {
    localStorage.removeItem('_expiredTime')
    clearInterval(this.interval)
    window.removeEventListener('mousemove', this.eventHandler)
    window.removeEventListener('scroll', this.eventHandler)
    window.removeEventListener('keydown', this.eventHandler)
  }
}

export default IdleTimer
