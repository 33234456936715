import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useSelector, useDispatch } from 'react-redux'
import { BodyWrapper, Topbar } from './components'
import { getUser } from 'services/auth'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 56,
    minHeight: '100vh',
    [theme.breakpoints.up('sm')]: {
      paddingTop: 75,
    },
    backgroundColor: 'white',
  },
  progress: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  content: {
    height: '100%',
  },
}))

const Main = (props) => {
  const { children } = props
  const dispatch = useDispatch()
  const auth = useSelector((state) => state.auth)
  const classes = useStyles()

  useEffect(() => {
    if (!auth.user) {
      dispatch(getUser())
    }
  }, [dispatch, auth.user])

  if (!auth.user) {
    return (
      <div className={classes.progress}>
        <CircularProgress size="150px" />
      </div>
    )
  }
  return (
    <div className={classes.root}>
      <Topbar user={auth.user} />
      <BodyWrapper user={auth.user} className={classes.content}>
        {children}
      </BodyWrapper>
    </div>
  )
}

Main.propTypes = {
  children: PropTypes.node,
}

export default Main
