import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Button, Typography, Grid } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import GridList from '@material-ui/core/GridList'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart'
import SettingsIcon from '@material-ui/icons/Settings'
import GetAppIcon from '@material-ui/icons/GetApp'
import useStyles from './style'
import { Link as RouterLink } from 'react-router-dom'
import Paginator from 'components/Paginator'
import PrintIcon from '@material-ui/icons/Print'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useSelector } from 'react-redux'

TemplateGridList.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ).isRequired,
  pageSize: PropTypes.number,
  currentPage: PropTypes.number,
  sort: PropTypes.oneOf(['asc', 'desc']),
  sortBy: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pageSizes: PropTypes.array,
  totalCount: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isLoadList: PropTypes.bool.isRequired,
  handlePageChange: PropTypes.func.isRequired,
  handleSort: PropTypes.func,
  totalPage: PropTypes.number.isRequired,
  isNormalUserTemplate: PropTypes.bool,
  viewDetailModalById: PropTypes.func,
  viewDownloadDetailModalById: PropTypes.func,
  viewAddToCartModalById: PropTypes.func,
  viewDirectPrintModal: PropTypes.func,
  columns: PropTypes.number,
  isCatalog: PropTypes.bool,
  isTab: PropTypes.bool,
  editRedirect: PropTypes.string,
  viewRedirect: PropTypes.string,
}

TemplateGridList.defaultProps = {
  pageSize: 9,
  currentPage: 1,
  pageSizes: [5, 10, 50],
  sort: 'desc',
  columns: 5,
}

function TemplateGridList(props) {
  const classes = useStyles()
  const {
    title,
    data,
    currentPage,
    handlePageChange,
    isLoading,
    isLoadList,
    totalPage,
    isNormalUserTemplate,
    viewDetailModalById,
    viewDownloadDetailModalById,
    viewAddToCartModalById,
    viewDirectPrintModal,
    isCatalog,
    columns,
    isTab,
    editRedirect,
    viewRedirect,
  } = props
  const dataList = useMemo(() => data, [data])
  const url_prefix = process.env.REACT_APP_API_STORAGE_URL
  const auth_id = useSelector((state) => state.auth.user.data.id)

  const TemplateImage = ({ data }) => {
    let src_image_url = '/images/no_image.png'
    // check template if published
    if (data.is_published && !isCatalog) {
      src_image_url = `${url_prefix}/${data.thumbnail_preview_image_url}`
    }
    // check catalog if have a thumbnail
    if (isCatalog && data.thumbnail_preview_image_url) {
      src_image_url = `${url_prefix}/${data.thumbnail_preview_image_url}`
    }

    return (
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        className={classes.catalogImgContainer}
      >
        <img src={src_image_url} alt="canvas image." className={classes.catalogImg} />
      </Grid>
    )
  }

  const Thumbnail = ({ data }) => {
    // check creator of template
    if (data.owner.id === auth_id && !isNormalUserTemplate) {
      return (
        <RouterLink to={`${editRedirect}/${data.id}`}>
          <TemplateImage data={data} />
        </RouterLink>
      )
    } else if (data.owner.id !== auth_id && !isNormalUserTemplate) {
      return (
        <RouterLink to={`${viewRedirect}/${data.id}`}>
          <TemplateImage data={data} />
        </RouterLink>
      )
    } else if (isNormalUserTemplate) {
      return (
        <RouterLink to={`${editRedirect}/${data.id}`}>
          <TemplateImage data={data} />
        </RouterLink>
      )
    }
  }

  const getTemplateDetailById = (id) => {
    viewDetailModalById(id)
  }
  const getDownloadById = (data) => {
    viewDownloadDetailModalById(data)
  }

  const getTemplateInCartById = (id) => {
    viewAddToCartModalById(id)
  }

  const getDirectPrintById = (id) => {
    viewDirectPrintModal(id)
  }

  const viewIcon = (item) => {
    if (isNormalUserTemplate !== true && !isTab) {
      return (
        <Button className={classes.button}>
          <RouterLink to={`${title}/view/${item.id}`} className={classes.flexLink}>
            <SettingsIcon className={classes.tableEyeIcon} />
          </RouterLink>
        </Button>
      )
    } else if (isTab) {
      return (
        <Button className={classes.button}>
          <RouterLink to={`view/${item.id}`} className={classes.flexLink}>
            <SettingsIcon className={classes.tableEyeIcon} />
          </RouterLink>
        </Button>
      )
    }
  }

  const onPageChange = (event, value) => {
    handlePageChange(value)
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        {isLoadList ? (
          dataList.length === 0 ? (
            <Typography variant="h5" component="h2" className={classes.emptyLabel}>
              データがありません
            </Typography>
          ) : (
            <GridList cols={columns}>
              {dataList.map((tile) => (
                <Card className={classes.card} key={tile.id}>
                  <Thumbnail data={tile} />
                  <CardContent>
                    <Grid container direction="row">
                      <Grid item container direction="column" sm={12} md={8}>
                        {isNormalUserTemplate !== true ? (
                          <Grid item sm={12}>
                            {isCatalog ? (
                              <Typography
                                variant="h5"
                                component="h2"
                                className={classes.cardTitle}
                                noWrap={true}
                              >
                                {tile.name}
                              </Typography>
                            ) : (
                              <Typography
                                variant="h5"
                                component="h2"
                                className={classes.cardTitle}
                                noWrap={true}
                              >
                                <Button className={classes.cardStatus}>
                                  {tile.is_published === 0 ? '下書き' : '公開済'}
                                </Button>
                                {tile.name}
                              </Typography>
                            )}
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              component="p"
                              noWrap={true}
                            >
                              {tile.owner_name}
                            </Typography>
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              component="p"
                              noWrap={true}
                            >
                              {tile.template_size_name}
                            </Typography>
                          </Grid>
                        ) : (
                          <Grid item>
                            <Button size="small" onClick={() => getTemplateDetailById(tile.id)}>
                              <Typography className={classes.titleButton}>{tile.name}</Typography>
                            </Button>
                          </Grid>
                        )}
                      </Grid>
                      <Grid item container direction="row" sm={12} md={4} justify="flex-end">
                        <Grid item md={6}>
                          <Button
                            className={classes.button}
                            onClick={() => getTemplateInCartById(tile.id)}
                            disabled={!(tile.is_published === 1 || isCatalog)}
                          >
                            <AddShoppingCartIcon className={classes.tableEyeIcon} />
                          </Button>
                        </Grid>
                        <Grid item md={6}>
                          <Button
                            className={classes.button}
                            onClick={() => getDownloadById(tile)}
                            disabled={tile.is_published === 1 || isCatalog ? false : true}
                          >
                            <GetAppIcon className={classes.tableEyeIcon} />
                          </Button>
                        </Grid>
                        <Grid item md={6}>
                          {isNormalUserTemplate ? (
                            <Button
                              className={classes.button}
                              disabled={tile.is_published === 1 || isCatalog ? false : true}
                              onClick={(event) => {
                                if (!(tile.is_published === 1 || isCatalog)) {
                                  event.preventDefault()
                                } else {
                                  getDirectPrintById(tile.id)
                                }
                              }}
                            >
                              <PrintIcon className={classes.tableEyeIcon} />
                            </Button>
                          ) : (
                            <Button
                              className={classes.button}
                              disabled={tile.is_published === 1 || isCatalog ? false : true}
                              onClick={(event) => {
                                if (!(tile.is_published === 1 || isCatalog)) {
                                  event.preventDefault()
                                } else {
                                  getDirectPrintById(tile.id)
                                }
                              }}
                            >
                              <PrintIcon className={classes.tableEyeIcon} />
                            </Button>
                          )}
                        </Grid>
                        <Grid item md={6}>
                          {viewIcon(tile)}
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              ))}
            </GridList>
          )
        ) : (
          <div className={classes.loaderContainer}>
            <CircularProgress />
          </div>
        )}
        <Paginator
          pageCount={totalPage}
          currentPage={currentPage}
          itemCount={dataList.length}
          itemsPerPage={10}
          onPageChange={isLoading ? () => {} : onPageChange}
        />
      </Paper>
    </div>
  )
}

export default TemplateGridList
