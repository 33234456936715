import React from 'react'
import PropTypes from 'prop-types'
import { TextField } from '@material-ui/core'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  textField: {
    border: 'unset',
    '& input': {
      backgroundColor: 'white',
      padding: 12,
      borderRadius: 5,
    },
    '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      border: 'unset',
    },
  },
}))

function TextFieldPrimitive({ style, ...others }) {
  const classes = useStyles()

  return <TextField variant="outlined" className={clsx(classes.textField, style)} {...others} />
}

TextFieldPrimitive.propTypes = {
  style: PropTypes.string,
}

export default TextFieldPrimitive
