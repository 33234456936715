import React from 'react'
import { Typography, IconButton, Paper, InputBase, MenuList } from '@material-ui/core'
import { Popper, Grow, ClickAwayListener, MenuItem, Button } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import PropTypes from 'prop-types'
import useStyles from './style'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import GetAppIcon from '@material-ui/icons/GetApp'

function LogHeaderContent(props) {
  const classes = useStyles()
  const anchorRef = React.useRef(null)
  const [open, setOpen] = React.useState(false)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  return (
    <div className={classes.root}>
      <Typography variant="h5" className={classes.title}>
        {props.title}
      </Typography>
      <div className={classes.titleWrapper}>
        <div>
          <Button
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            className={classes.button}
            style={{ minWidth: 200, justifyContent: 'space-around' }}
          >
            <GetAppIcon className={classes.icon} />
            エクスポート
            <ExpandMoreIcon />
          </Button>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            placement="bottom-end"
            modifiers={{
              offset: {
                enabled: true,
                offset: '-20, 0',
              },
            }}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper className={classes.paper}>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={open}
                      id="menu-list-grow"
                      onKeyDown={handleListKeyDown}
                      className={classes.menu}
                    >
                      <MenuItem className={classes.menuItem} onClick={props.downloadCSV}>
                        <GetAppIcon className={classes.icon} />
                        CSV
                      </MenuItem>
                      <MenuItem className={classes.menuItem} onClick={props.downloadPDF}>
                        <GetAppIcon className={classes.icon} />
                        PDF
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
        <Paper component="form" className={classes.searchBar} onSubmit={props.onSearch}>
          <InputBase
            className={classes.input}
            placeholder="検索"
            name="keyword"
            value={props.valueSearch}
            onChange={props.onSearchChange}
          />
          <IconButton type="submit" className={classes.iconButton} aria-label="search">
            <SearchIcon />
          </IconButton>
        </Paper>
      </div>
    </div>
  )
}

LogHeaderContent.propTypes = {
  title: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
  valueSearch: PropTypes.string.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  downloadCSV: PropTypes.func,
  downloadPDF: PropTypes.func,
}

export default LogHeaderContent
