import React from 'react'
import { Switch } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

CustomSwitch.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
}

const StyleSwitch = withStyles((theme) => ({
  switchBase: {
    color: theme.palette.green,
    '&$checked': {
      color: 'white',
    },
    '&$checked + $track': {
      opacity: 1,
      backgroundColor: theme.palette.green,
    },
  },
  thumb: {
    border: `1px solid ${theme.palette.green}`,
  },
  checked: {},
  track: {
    border: `2px solid ${theme.palette.green}`,
    backgroundColor: 'white',
  },
}))(Switch)

function CustomSwitch(props) {
  const { checked, onChange } = props

  return <StyleSwitch checked={checked} onChange={onChange} />
}

export default CustomSwitch
