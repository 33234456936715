export default {
  root: {
    '& fieldset': {
      borderColor: 'white',
    },
    '&:hover fieldset': {
      borderColor: 'white !important',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white !important',
    },
  },
}
