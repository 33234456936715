export default {
  root: {
    fontSize: 13,
  },
  gutterBottom: {
    marginBottom: 8,
  },
  body1: {
    fontSize: 12,
  },
  h6: {
    fontSize: 13,
  },
}
