import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Container } from '@material-ui/core'
import { Sidebar } from '.'
import clsx from 'clsx'

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: 'unset',
  },
  content: {
    height: '100%',
    marginTop: -50,
    paddingLeft: 60,
  },
  userContent: {
    paddingTop: 75,
    paddingLeft: 0,
  },
}))

const BodyWrapper = (props) => {
  const { children, user } = props
  const classes = useStyles()

  const [openSidebar, setOpenSidebar] = useState(false)
  const shouldOpenSidebar = openSidebar
  const userContent = user.data.role == 'User' ? true : false

  const handleSidebarOpen = () => {
    setOpenSidebar(!openSidebar)
  }
  return (
    <Container className={classes.root}>
      {!userContent ? (
        <Sidebar
          user={user}
          onClose={handleSidebarOpen}
          open={shouldOpenSidebar}
          variant="permanent"
        />
      ) : null}
      <main
        className={clsx(classes.content, {
          [classes.userContent]: userContent,
        })}
      >
        {children}
      </main>
    </Container>
  )
}

BodyWrapper.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  user: PropTypes.object,
}

export default BodyWrapper
