import * as types from './actionTypes'

const initialState = {
  options: {
    specs: [],
    sizes: [],
    papers: [],
    surfaces: [],
  },
  list: [],
  meta: {
    totalCount: 0,
    currentPage: 1,
    lastPage: 1,
    prevPageUrl: null,
    nextPageUrl: null,
  },
  search: {
    keyword: '',
    limit: 5,
    page: 1,
    sort: 'desc',
    sortBy: 'updated_at',
    theme_ids: [],
  },
  isDisabled: true,
  printUrl: '',
  isPrinting: false,
  isDownloading: false,
}

const reducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case types.FETCH_CART_DROPDOWN_OPTIONS:
      return {
        ...state,
        options: {
          specs: payload.specs,
          sizes: payload.sizes,
          papers: payload.papers,
          surfaces: payload.surfaces,
        },
      }
    case types.FETCH_CART_DATA:
      return {
        ...state,
        list: payload.data,
        meta: {
          totalCount: payload.meta.total,
          currentPage: payload.meta.currentPage,
          lastPage: payload.meta.lastPage,
          prevPageUrl: payload.meta.prevPageUrl,
          nextPageUrl: payload.meta.nextPageUrl,
        },
        search: {
          ...state.search,
          page: payload.meta.currentPage,
        },
        isDisabled: payload.data.length === 0,
      }
    case types.CREATE_CART_DATA:
    case types.UPDATE_CART_DATA:
      return {
        ...state,
        list: state.list.map((item) => (item.id === payload.id ? payload : item)),
      }
    case types.DELETE_CART_DATA:
      return {
        ...state,
      }
    case types.CLEAR_CART:
      return {
        ...state,
        list: [],
        isDisabled: true,
      }
    case types.SET_PRINT_URL:
      return {
        ...state,
        printUrl: payload.url,
      }
    case types.SET_PRINTING:
      return {
        ...state,
        isPrinting: payload,
      }
    case types.SET_DOWNLOADING:
      return {
        ...state,
        isDownloading: payload,
      }
    default:
      return state
  }
}

export default reducer
