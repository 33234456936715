import * as types from './actionTypes'

export function actionGetAnnouncementList(announcementDetails) {
  return {
    type: types.GET_ANNOUNCEMENT_LIST,
    payload: announcementDetails,
  }
}

export function actionGetDetailsById(announcementDetails) {
  return {
    type: types.GET_ANNOUNCEMENT_DETAIL_BY_ID,
    payload: announcementDetails,
  }
}

export function actionGetSessions(templateFilterList) {
  return {
    type: types.GET_TEMPLATE_FILTERS,
    payload: templateFilterList,
  }
}

export function actionGetTemplateList(templateList) {
  return {
    type: types.GET_TEMPLATE_LIST,
    payload: templateList,
  }
}

export function actionSetSearchCriteria(keyword, page, limit, sort, sortBy, themes_id) {
  return {
    type: types.SET_TEMPLATE_SEARCH_CRITERIA,
    payload: { keyword, page, limit, sort, sortBy, themes_id },
  }
}

export function actionGetTemplateDetailById(templateList) {
  return {
    type: types.GET_TEMPLATE_DETAILS_BY_ID,
    payload: templateList,
  }
}

export function actionSetActiveTemplates(activeTemplate) {
  return {
    type: types.SET_ACTIVE_TEMPLATES,
    payload: activeTemplate,
  }
}
