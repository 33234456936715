import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Drawer, IconButton } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import BusinessIcon from '@material-ui/icons/Business'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import CreateIcon from '@material-ui/icons/Create'
import PaletteIcon from '@material-ui/icons/Palette'
import ImageIcon from '@material-ui/icons/Image'
import NotificationsIcon from '@material-ui/icons/Notifications'
import { SidebarNav } from './components'

const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    marginTop: 75,
    height: 'calc(100% - 75px)',
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: 55,
  },
  root: {
    backgroundColor: '#E3E3E3',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  menuDiv: {
    width: '100%',
    backgroundColor: '#E3E3E3',
  },
  menuButton: {
    marginLeft: 3,
    color: '#757575',
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
}))

const Sidebar = (props) => {
  const { user, open, variant, onClose, className, ...rest } = props

  const classes = useStyles()
  const prefix =
    user.data.role === 'Administrator'
      ? 'admin'
      : user.data.role === 'CompanyAdmin'
      ? 'company-admin'
      : 'user'

  var pages = [
    {
      title: '企業管理',
      href: `/${prefix}/companies`,
      icon: <BusinessIcon />,
    },
    {
      title: 'ユーザー管理',
      href: `/${prefix}/users`,
      icon: <AccountCircleIcon />,
    },
    {
      title: '素材管理',
      href: `/${prefix}/materials`,
      icon: <ImageIcon />,
    },
    {
      title: 'テーマ管理',
      href: `/${prefix}/themes`,
      icon: <PaletteIcon />,
    },
    {
      title: 'テンプレート管理',
      href: `/${prefix}/templates`,
      icon: <CreateIcon />,
    },
    {
      title: 'アナウンス管理',
      href: `/${prefix}/announcement`,
      icon: <NotificationsIcon />,
    },
  ]

  if (user.data.role != 'Administrator') {
    pages = pages.splice(1)
  }

  return (
    <Drawer
      anchor="left"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div className={classes.menuDiv}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={onClose}
          edge="start"
          className={clsx(classes.menuButton)}
        >
          <MenuIcon />
        </IconButton>
      </div>
      <div {...rest} className={clsx(classes.root, className)}>
        <SidebarNav className={classes.nav} pages={pages} open={open} />
      </div>
    </Drawer>
  )
}

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
  user: PropTypes.object,
}

export default Sidebar
