import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
// import TableSortLabel from '@material-ui/core/TableSortLabel'
import PropTypes from 'prop-types'
import React from 'react'
import { withStyles } from '@material-ui/core/styles'

Head.propTypes = {
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  headCells: PropTypes.array.isRequired,
}

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#41CCC0',
    color: '#ffffff',
  },
}))(TableCell)

function Head(props) {
  const { order, orderBy, headCells } = props

  return (
    <TableHead>
      <TableRow>
        {headCells.map(
          (headCell) =>
            headCell.hide !== true && (
              <StyledTableCell
                key={headCell.id}
                align={headCell.headerAlign ? headCell.headerAlign : 'left'}
                padding={headCell.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                {headCell.label}
              </StyledTableCell>
            )
        )}
      </TableRow>
    </TableHead>
  )
}

export default React.memo(Head)
