import React, { useEffect, useState } from 'react'
import { Tooltip } from '@material-ui/core'
import PropTypes from 'prop-types'
import ImageIcon from '@material-ui/icons/ImageOutlined'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  emptyImageIcon: {
    width: 250,
    height: 250,
    cursor: 'pointer',
  },
  imageIcon: {
    height: 'auto',
    with: 'auto',
    margin: 'auto',
    cursor: 'pointer',
    maxHeight: 250,
    maxWidth: 250,
    borderRadius: 10,
  },
  imageContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

function ImageInput({ value, forwardedRef, name, h, w, onChange, ...rest }) {
  const classes = useStyles()
  const [avatarUrl, setAvatarUrl] = useState(null)

  useEffect(() => {
    if (value.length > 0) {
      switch (typeof value) {
        case 'string':
          setAvatarUrl(`${process.env.REACT_APP_API_STORAGE_URL}${value}`)
          break
        case 'object':
          setAvatarUrl(URL.createObjectURL(value[0]))
          break
      }
    }
  }, [value])

  const onFileChange = (e) => {
    const selectedFile = e.target.files[0]
    const idxDot = selectedFile.name.lastIndexOf('.')
    const extFile = selectedFile.name.substr(idxDot, selectedFile.name.length).toLowerCase()
    const acceptedFile = rest.accept.split(',')
    if (acceptedFile.includes(extFile)) {
      onChange(e)
    }
  }

  return (
    <>
      <input
        type="file"
        className={classes.fileInput}
        {...rest}
        ref={forwardedRef}
        name={name}
        onChange={onFileChange}
      />
      <Tooltip title="画像を選択">
        <label color="primary" htmlFor={name} className={classes.imageContainer}>
          {avatarUrl ? (
            <img
              src={avatarUrl}
              alt=""
              className={classes.imageIcon}
              style={{ maxHeight: h, maxWidth: w }}
            />
          ) : (
            <ImageIcon
              color="primary"
              className={classes.emptyImageIcon}
              style={{ height: h, width: w }}
            />
          )}
        </label>
      </Tooltip>
    </>
  )
}

ImageInput.propTypes = {
  value: PropTypes.any.isRequired,
  forwardedRef: PropTypes.object,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  h: PropTypes.number,
  w: PropTypes.number,
}

export default ImageInput
