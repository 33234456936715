import React from 'react'
import { Select, Grid } from '@material-ui/core'
import HelperTextError from './HelperTextError'
import PropTypes from 'prop-types'

SelectInput.propTypes = {
  children: PropTypes.node.isRequired,
  helperText: PropTypes.string,
}

function SelectInput(props) {
  const { children, helperText, ...attributes } = props
  return (
    <Grid container direction="column">
      <Select {...attributes}>{children}</Select>
      {helperText && <HelperTextError>{helperText}</HelperTextError>}
    </Grid>
  )
}

export default SelectInput
